* {
  font-family: "Roboto", sans-serif;
}

textarea,
select,
input,
button {
  outline: none;
}

.up-header {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.7px;
  text-align: left;
  color: #3f3f3f;
  line-height: 37px;
  padding-left: 21px;
}

.app {
  background-color: $primary-background-color;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.page {
  max-width: 650px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 80px;
}

.subtitle {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.7px;
  text-align: center;
  color: #3f3f3f;
  text-align: left;
  line-height: 37px;
  padding-left: 14px;
  padding-right: 14px;
}

.inputForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--lined {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 17px;
    margin-bottom: 17px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px;
}

  &--underlined {
      border-bottom: 1px solid #d5d5d5;
      padding-bottom: 17px;
      margin-bottom: 17px;
      padding-left: 32px;
      padding-right: 32px;
      margin-left: -32px;
      margin-right: -32px;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: 0.6px;
    text-align: left;
    width: 100%;
    color: #1d1d1d;
  }

  .inputForm-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .css-10nd86i {
      background-color: #fff;
      width: calc(100% - 22px);
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ed1c24;
      line-height: 27px;
      padding: 0px 13px;
      border: none;
      border-radius: 2px;
      border-bottom: 2px solid #ed1c24;
      margin-left: 13px;

      .css-1oubc3t {
        border: none !important;
        box-shadow: none !important;
      }

      .css-rcs6yu {
        border: none !important;
        box-shadow: none !important;
      }

      .css-vj8t7z {
        border: none !important;
      }

      .css-vj8t7z:hover {
        border: none !important;
        box-shadow: none !important;
      }

      .css-2o5izw {
        border: none !important;
        box-shadow: none !important;
      }
    }

    input {
      background-color: #fff;
      width: calc(100% - 22px);
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ed1c24;
      line-height: 27px;
      padding: 0px 13px;
      border: none;
      border-radius: 2px;
      border-bottom: 2px solid #ed1c24;
      margin-left: 13px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .inputForm-container {
    justify-content: start !important;
  }
}

.inputFormSelect > div {
  width: 100%;
}

.header-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  font-size: 14px;
  color: #ededed;
  height: 49px;
  text-align: center;
  line-height: 49px;

  .title {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: 0.9px;
    color: #ed1c24;
    flex-grow: 1;
    text-align: center;
  }

  .action-button {
    width: 42px;
    height: 22px;
    border-radius: 2px;
    text-align: center;
    line-height: 22px;
    color: #ed1c24;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer;

    img {
      height: 18px;
      width: auto;
    }

    &--left {
      text-align: left;
      padding-left: 10px;
    }

    &--right {
      text-align: right;
      padding-right: 10px;
    }
  }
}

.box {
  background-color: $white;
  margin-bottom: 1px;
  width: 100%;

  .header {
    background-color: #eeeded;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: 0.7px;
    text-align: left;
    padding: 0px 32px;
    color: #3f3f3f;
    line-height: 35px;
  }

  .body {
    padding: 12px 32px;
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
      width: 100%;
      font-size: 14px;
      line-height: 25px;
      flex-grow: 1;
      text-align: center;
      color: $white;
      background-color: $primary-color-shade-light;
    }

    .body {
      width: calc(100% - 40px);
      padding: 20px;

      .inputForm {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          display: block;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #121212;
          padding-bottom: 5px;
          padding-top: 0px;
        }

        .buttonGroup {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            margin: 0px;
            flex-grow: 1;
          }

          .button {
            background-color: $primary-color;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $white;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            line-height: 27px;
            margin-top: 0px;
            width: 42px;
            text-align: center;
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-left: solid 7px #66cb93;
    cursor: pointer;

    .icon {
      padding-left: 17px;
      padding-right: 35px;
      margin-right: 11px;
      color: #121212;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      line-height: 39px;

      svg {
        width: 16px;
        height: 100%;
        object-fit: contain;
      }
    }

    .title {
      font-size: 14px;
      line-height: 39px;
      flex-grow: 1;
    }

    .action-button {
      width: 42px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      color: #1a1a1a;
      margin-left: 5px;
      margin-right: 13px;
      cursor: pointer;

      &--active {
        background-color: #ed1c24;
        color: #fff;
      }
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.button {
  text-transform: uppercase;
  display: block;
  width: 100%;
  background-color: #d5d4d4;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: center;
  color: #404041;
  margin-top: 8px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;

  &--primary {
    background-color: $primary-color;
    color: #fff;
  }
}

.tabbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #d5d4d4;
  border-radius: 2px;

  .tabbar-button {
    color: #3f3f40;
    font-size: 12px;
    line-height: 27px;
    text-align: center;
    flex-grow: 1;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }

    &--active {
      background-color: #ed1c24;
      border-radius: 2px;
      color: #fff;
    }
  }
}

/**
* Helper classes
*/
.mt-5 {
  margin-top: 5px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

/**
* Icons
*/
.more-icon {
  width: 21px;
  height: 6px;
}

.device-detail {
  background-color: #fff;
  min-height: calc(100vh - 49px);
  margin-bottom: -80px;
  padding-bottom: 80px;
}


// Datepicker
.react-datepicker__day--selected {
  background-color: #ed1c24 !important;
  color: #fff !important;
}

@media only screen and (min-width: 600px) {
  .ReactModal__Content {
    margin-left: auto;
    margin-right: auto;
    max-width: 650px;
  }
}