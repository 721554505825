.no-data-added-yet {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: 0.8px;
  text-align: center;
  color: #1d1d1d;
  padding: 10px 20px;
  background-color: #fff;
}
