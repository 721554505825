@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

* {
  font-family: "Roboto", sans-serif; }

textarea,
select,
input,
button {
  outline: none; }

.up-header {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.7px;
  text-align: left;
  color: #3f3f3f;
  line-height: 37px;
  padding-left: 21px; }

.app {
  background-color: #f2f0f0;
  width: 100vw;
  height: 100vh;
  overflow: auto; }

.page {
  max-width: 650px;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0px;
  padding-bottom: 80px; }

.subtitle {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.86;
  letter-spacing: 0.7px;
  text-align: center;
  color: #3f3f3f;
  text-align: left;
  line-height: 37px;
  padding-left: 14px;
  padding-right: 14px; }

.inputForm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .inputForm--lined {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 17px;
    margin-bottom: 17px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -20px;
    margin-right: -20px; }
  .inputForm--underlined {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 17px;
    margin-bottom: 17px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: -32px;
    margin-right: -32px; }
  .inputForm span {
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: 0.6px;
    text-align: left;
    width: 100%;
    color: #1d1d1d; }
  .inputForm .inputForm-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .inputForm .inputForm-container .css-10nd86i {
      background-color: #fff;
      width: calc(100% - 22px);
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ed1c24;
      line-height: 27px;
      padding: 0px 13px;
      border: none;
      border-radius: 2px;
      border-bottom: 2px solid #ed1c24;
      margin-left: 13px; }
      .inputForm .inputForm-container .css-10nd86i .css-1oubc3t {
        border: none !important;
        box-shadow: none !important; }
      .inputForm .inputForm-container .css-10nd86i .css-rcs6yu {
        border: none !important;
        box-shadow: none !important; }
      .inputForm .inputForm-container .css-10nd86i .css-vj8t7z {
        border: none !important; }
      .inputForm .inputForm-container .css-10nd86i .css-vj8t7z:hover {
        border: none !important;
        box-shadow: none !important; }
      .inputForm .inputForm-container .css-10nd86i .css-2o5izw {
        border: none !important;
        box-shadow: none !important; }
    .inputForm .inputForm-container input {
      background-color: #fff;
      width: calc(100% - 22px);
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #ed1c24;
      line-height: 27px;
      padding: 0px 13px;
      border: none;
      border-radius: 2px;
      border-bottom: 2px solid #ed1c24;
      margin-left: 13px; }

@media only screen and (min-width: 600px) {
  .inputForm-container {
    -webkit-justify-content: start !important;
            justify-content: start !important; } }

.inputFormSelect > div {
  width: 100%; }

.header-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  font-size: 14px;
  color: #ededed;
  height: 49px;
  text-align: center;
  line-height: 49px; }
  .header-box .title {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: 0.9px;
    color: #ed1c24;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: center; }
  .header-box .action-button {
    width: 42px;
    height: 22px;
    border-radius: 2px;
    text-align: center;
    line-height: 22px;
    color: #ed1c24;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
    cursor: pointer; }
    .header-box .action-button img {
      height: 18px;
      width: auto; }
    .header-box .action-button--left {
      text-align: left;
      padding-left: 10px; }
    .header-box .action-button--right {
      text-align: right;
      padding-right: 10px; }

.box {
  background-color: #fff;
  margin-bottom: 1px;
  width: 100%; }
  .box .header {
    background-color: #eeeded;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.86;
    letter-spacing: 0.7px;
    text-align: left;
    padding: 0px 32px;
    color: #3f3f3f;
    line-height: 35px; }
  .box .body {
    padding: 12px 32px; }
  .box .options {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center; }
    .box .options .title {
      width: 100%;
      font-size: 14px;
      line-height: 25px;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      text-align: center;
      color: #fff;
      background-color: #d24c51; }
    .box .options .body {
      width: calc(100% - 40px);
      padding: 20px; }
      .box .options .body .inputForm {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start; }
        .box .options .body .inputForm span {
          display: block;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #121212;
          padding-bottom: 5px;
          padding-top: 0px; }
        .box .options .body .inputForm .buttonGroup {
          width: 100%;
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-align-items: flex-start;
                  align-items: flex-start; }
          .box .options .body .inputForm .buttonGroup input {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            margin: 0px;
            -webkit-flex-grow: 1;
                    flex-grow: 1; }
          .box .options .body .inputForm .buttonGroup .button {
            background-color: #ed1c24;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            line-height: 27px;
            margin-top: 0px;
            width: 42px;
            text-align: center; }
  .box .row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    border-left: solid 7px #66cb93;
    cursor: pointer; }
    .box .row .icon {
      padding-left: 17px;
      padding-right: 35px;
      margin-right: 11px;
      color: #121212;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      line-height: 39px; }
      .box .row .icon svg {
        width: 16px;
        height: 100%;
        object-fit: contain; }
    .box .row .title {
      font-size: 14px;
      line-height: 39px;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
    .box .row .action-button {
      width: 42px;
      height: 22px;
      border-radius: 2px;
      text-align: center;
      line-height: 22px;
      font-size: 14px;
      color: #1a1a1a;
      margin-left: 5px;
      margin-right: 13px;
      cursor: pointer; }
      .box .row .action-button--active {
        background-color: #ed1c24;
        color: #fff; }
    .box .row:hover {
      background-color: #f8f8f8; }

.column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%; }

.button {
  text-transform: uppercase;
  display: block;
  width: 100%;
  background-color: #d5d4d4;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: center;
  color: #404041;
  margin-top: 8px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none; }
  .button--primary {
    background-color: #ed1c24;
    color: #fff; }

.tabbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #d5d4d4;
  border-radius: 2px; }
  .tabbar .tabbar-button {
    color: #3f3f40;
    font-size: 12px;
    line-height: 27px;
    text-align: center;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    cursor: pointer; }
    .tabbar .tabbar-button svg {
      margin-right: 5px; }
    .tabbar .tabbar-button--active {
      background-color: #ed1c24;
      border-radius: 2px;
      color: #fff; }

/**
* Helper classes
*/
.mt-5 {
  margin-top: 5px; }

.mt-15 {
  margin-top: 15px; }

.mt-10 {
  margin-top: 10px; }

.ml-10 {
  margin-left: 10px; }

.mr-10 {
  margin-right: 10px; }

.p-10 {
  padding: 10px; }

.p-20 {
  padding: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.mt-20 {
  margin-top: 20px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pt-10 {
  padding-top: 10px !important; }

.mt-0 {
  margin-top: 0px !important; }

/**
* Icons
*/
.more-icon {
  width: 21px;
  height: 6px; }

.device-detail {
  background-color: #fff;
  min-height: calc(100vh - 49px);
  margin-bottom: -80px;
  padding-bottom: 80px; }

.react-datepicker__day--selected {
  background-color: #ed1c24 !important;
  color: #fff !important; }

@media only screen and (min-width: 600px) {
  .ReactModal__Content {
    margin-left: auto;
    margin-right: auto;
    max-width: 650px; } }

.LoginFrontPage {
  -webkit-align-items: center;
          align-items: center;
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 0px !important; }
  .LoginFrontPage .logo {
    width: 209px;
    height: 30px;
    object-fit: contain;
    margin-bottom: 32px;
    margin-top: 37px; }
  .LoginFrontPage .logoshape {
    margin-top: 28px;
    width: 96px;
    height: 94px;
    object-fit: contain; }
  .LoginFrontPage .errorMessage {
    color: red;
    padding: 10px;
    padding-top: 16px;
    text-align: center; }
  .LoginFrontPage .btn-container {
    width: calc(100% - 64px);
    padding: 0px 32px; }

.loadingContainer {
  width: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.full-height-loading-container {
  min-height: calc(90vh - 55px);
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #ed1c24;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ed1c24 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.modal .modal-body .edit-container {
  padding: 0px 20px;
  padding-top: 20px; }

.modal .modal-body .edit-container {
  padding: 0px 20px;
  padding-top: 20px; }

.chartdiagramm-container {
  width: 100%; }

.chartdiagramm-unit {
  font-family: "Roboto";
  font-size: 35px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #222222;
  padding-bottom: 13px; }

.no-data-available {
  padding: 20px;
  text-align: center; }

.chartdiagramm-body {
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 24px; }

.chartdiagramm-header {
  display: -webkit-flex;
  display: flex;
  background-color: #eeeded; }
  .chartdiagramm-header .chartdiagramm-title {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #3f3f3f;
    line-height: 37px;
    padding-left: 21px;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .chartdiagramm-header .chartdiagramm-btn {
    padding-right: 17px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    justify-items: center;
    cursor: pointer; }
    .chartdiagramm-header .chartdiagramm-btn img {
      width: 21px;
      height: auto; }

.tachometer-container .tachometer-header {
  height: 37px;
  background-color: #eeeded;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.7px;
  text-align: left;
  color: #3f3f3f;
  line-height: 37px;
  padding-left: 21px; }

.tachometer-container .tachometer-body {
  width: 100%;
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 24px; }

.gauge-wrapper {
  display: table; }
  .gauge-wrapper .gauge {
    width: 226px;
    position: relative;
    display: table;
    overflow: hidden; }
    .gauge-wrapper .gauge .leaf,
    .gauge-wrapper .gauge .pointer {
      width: 100%; }
    .gauge-wrapper .gauge .leaf {
      display: table-cell; }
    .gauge-wrapper .gauge .pointer {
      position: absolute;
      bottom: -10px;
      left: 0;
      -webkit-transition: -webkit-transform 0.3s linear;
      transition: -webkit-transform 0.3s linear;
      transition: transform 0.3s linear;
      transition: transform 0.3s linear, -webkit-transform 0.3s linear; }
  .gauge-wrapper .labelPercentage {
    margin-top: -23px;
    line-height: 23px;
    font-family: "Roboto";
    text-align: center;
    font-size: 35px; }
  .gauge-wrapper .labelMin,
  .gauge-wrapper .labelMax {
    bottom: 0; }
  .gauge-wrapper .labelMin {
    float: left; }
  .gauge-wrapper .labelMax {
    float: right; }

.gauge {
  width: 226px;
  position: relative;
  display: table; }
  .gauge .leaf, .gauge .pointer {
    width: 100%; }
  .gauge .leaf {
    display: table-cell; }
  .gauge .pointer {
    position: absolute;
    bottom: -10px;
    left: 0; }

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 99; }

.ReactModal__Content {
  position: absolute !important;
  top: auto !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  max-height: 90vh !important;
  border: 0px solid #cccccc !important;
  background-color: white !important;
  overflow: auto !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  outline: none !important;
  padding: 0px !important; }

.modal .modal-header {
  display: -webkit-flex;
  display: flex;
  border-bottom: 1px solid #eeeded; }
  .modal .modal-header .title {
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 46px;
    letter-spacing: 0.9px;
    text-align: center;
    color: #ed1c24;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .modal .modal-header .btn {
    line-height: 46px;
    padding: 0px 15px;
    color: #ed1c24;
    cursor: pointer; }
    .modal .modal-header .btn--black {
      color: #000000; }

.modal .modal-body .range-tags {
  border-bottom: 1px solid #eeeded;
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .modal .modal-body .range-tags .range-tag-button {
    width: calc(15% - 12px);
    padding: 8px 14px;
    margin-right: 12px;
    margin-bottom: 12px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background-color: #d5d4d4;
    color: #404041;
    cursor: pointer; }
    .modal .modal-body .range-tags .range-tag-button--active {
      background-color: #e10808;
      color: #ffffff; }

.modal .modal-body .doneBtn {
  padding: 20px; }

.modal .modal-body .edit-container {
  padding: 0px 20px;
  padding-top: 20px; }

.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-direction: row;
          flex-direction: row;
  z-index: 50; }
  .footer .footer-item {
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .footer .footer-item img {
      width: auto;
      height: 24px;
      margin-top: 5px; }
    .footer .footer-item div {
      font-size: 9px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.89;
      letter-spacing: 0.5px;
      text-align: center;
      color: #373737; }
    .footer .footer-item.active div {
      color: #ed1c24 !important; }
  .footer .footer-logo {
    display: none; }

@media only screen and (min-width: 600px) {
  .footer-logo {
    display: block !important;
    width: calc(100% - 32px);
    max-width: 168px;
    margin-top: 32px;
    margin-right: 32px; }
  .app {
    background-color: #fff; }
  .footer {
    background-color: #f2f0f0;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: auto !important;
    right: initial !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 232px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .footer .footer-item {
      -webkit-flex-direction: row;
              flex-direction: row;
      width: 100%;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      padding-top: 32px; }
      .footer .footer-item img {
        margin-left: 16px;
        margin-right: 16px; }
      .footer .footer-item div {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        text-align: left;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px; }
    .footer .footer-item:hover {
      opacity: 0.75; } }

.modal .modal-body .edit-container {
  padding: 0px 20px;
  padding-top: 20px; }

.alert-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #fff;
  padding: 10px 20px;
  margin-bottom: 1px; }
  .alert-item .icon {
    height: 14px;
    width: auto; }
  .alert-item .title {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    letter-spacing: 0.8px;
    text-align: left;
    color: #1d1d1d;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-left: 20px; }
  .alert-item .action {
    font-size: 12px;
    color: #ed1c24;
    cursor: pointer; }

.no-data-added-yet {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: 0.8px;
  text-align: center;
  color: #1d1d1d;
  padding: 10px 20px;
  background-color: #fff; }

