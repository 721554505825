.tachometer-container {
  .tachometer-header {
    height: 37px;
    background-color: #eeeded;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #3f3f3f;
    line-height: 37px;
    padding-left: 21px;
  }
  .tachometer-body {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
  }
}

.gauge-wrapper {
  display: table; 

  .gauge {
    width: 226px;
    position: relative;
    display: table;
    overflow: hidden;

    .leaf,
    .pointer {
      width: 100%;
    }

    .leaf {
      display: table-cell;
    }

    .pointer {
      position: absolute;
      bottom: -10px;
      left: 0;
      transition: transform 0.3s linear;
    }
  }

  .labelPercentage {
    margin-top: -23px;
    line-height: 23px;
    font-family: "Roboto";
    text-align: center;
    font-size: 35px;
  }

  .labelMin,
  .labelMax {
    bottom: 0;
  }

  .labelMin {
    float: left;
  }

  .labelMax {
    float: right;
  }
}

.gauge {
  width: 226px;
  position: relative;
  display: table;

  .leaf,.pointer {
    width: 100%;
  }

  .leaf {
    display: table-cell;
  }

  .pointer {
    position: absolute;
    bottom: -10px;
    left: 0;
  }
}
