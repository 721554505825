 .LoginFrontPage {
  align-items: center;
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 0px !important;

  .logo {
    width: 209px;
    height: 30px;
    object-fit: contain;
    margin-bottom: 32px;
    margin-top: 37px;
  }

  .logoshape {
    margin-top: 28px;
    width: 96px;
    height: 94px;
    object-fit: contain;
  }

  .errorMessage {
    color: red;
    padding: 10px;
    padding-top: 16px;
    text-align: center;
  }

  .btn-container {
    width: calc(100% - 64px);
    padding: 0px 32px;
  }
}